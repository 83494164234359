<template>
  <div>
    <MainLayout>
      <SlideLayout :tabs="slideTabs">
        <template v-slot:info>
          <div class="keep__title">
            <p class="keep__text">Общая информация</p>
          </div>
          <BasicTableWithoutHeader :table-data="getDataRequisites">
          </BasicTableWithoutHeader>
          <div class="list ">
            <ValidationTextareaField
              class="validate-textarea-field "
              :disabled="true"
              v-model="sourceObject.text"
              label="Текст сообщения"
              validate-name="текст сообщения"
            ></ValidationTextareaField>
          </div>
          <div
            class="list"
            style="display: flex; justify-content: flex-end ;box-shadow: none"
          >
            <v-btn
              width="154"
              height="42"
              style="margin-left: 33px; margin-bottom: 22px; margin-right: 15px;"
              class="ma-5 btn btn_color_white"
              text
              @click="$router.go(-1)"
            >
              Закрыть
            </v-btn>
          </div>
        </template>
      </SlideLayout>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import phoneFilter from "@/helpers/filters/phoneFilter";
import moment from "moment/moment";
import ValidationTextareaField from "@/components/ValidationField/ValidationTextareaField.vue";
export default {
  name: "PersonnelInfoPage",
  components: {
    ValidationTextareaField,
    BasicTableWithoutHeader,
    SlideLayout,
    MainLayout
  },
  data() {
    return {
      slideTabs: [
        {
          text: "Общая информация",
          value: "info",
          icon: "i"
        }
      ]
    };
  },
  computed: {
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getPageReviewList;
    },
    getDataRequisites() {
      return [
        {
          text: "Телефон",
          value: this?.sourceObject?.created_by?.phone_number
            ? phoneFilter.execute(this?.sourceObject?.created_by?.phone_number)
            : "-"
        },
        {
          text: "Дата отправки сообщения",
          value: this?.sourceObject?.created_at
            ? `${moment(this?.sourceObject?.created_at * 1000).format(
                "DD.MM.YYYY HH:mm"
              )}`
            : "-"
        }
      ];
    }
  },
  methods: {},
  props: {},
  created() {
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query,
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageReviewIdList").finally(() => {
      this.isDateLoaded = false;
    });
  }
};
</script>

<style scoped></style>
